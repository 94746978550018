import clsx from 'clsx';
import { AnimatePresence, motion, useIsPresent } from 'framer-motion';
import { Key, ReactNode, Ref, forwardRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import SelectListOption from './SelectListOption';
import { getOptionKey } from './utils';
import './SelectList.scss';

// TODO: consider abstracting into a common component when we re-use this more
// also see: VehicleList
const ListItem = forwardRef(function ListItem(
  { children }: { children: ReactNode },
  ref: Ref<HTMLDivElement>,
) {
  const isPresent = useIsPresent();

  return (
    <motion.div
      ref={ref}
      layout
      // having initial on select menus looks weird, and can be janky
      // initial={{ opacity: 0, x: -400, scale: 0.5 }}
      animate={{ opacity: 1, x: 0, scale: 1 }}
      exit={{ opacity: 0, x: -200, scale: 0.5 }}
      transition={{ duration: 0.6, type: 'spring' }}
      style={{
        position: isPresent ? 'static' : 'absolute',
      }}
    >
      {children}
    </motion.div>
  );
});

type Props<Option> = {
  selectedOptions: Readonly<Option[]>;
  disabledOptions?: Readonly<Option[]>;
  options: Readonly<Option[]>;
  highlightedOptionIndex: number;
  renderListOption?: (value: Option) => ReactNode;
  optionKeyPath?: string;
  className?: string;
  type?: 'checkbox' | 'radio';

  onScrollToBottom?: () => void;
  onOptionSelect?: (option: Option) => void;
  onOptionHighlight?: (option: Option) => void;
};

function SelectList<Option>({
  selectedOptions,
  disabledOptions,
  options,
  renderListOption,
  optionKeyPath,
  highlightedOptionIndex,
  onScrollToBottom,
  onOptionSelect,
  onOptionHighlight,
  className,
  type,
}: Props<Option>) {
  const [bottomRef, isBottom] = useInView();
  const [listElement, setListElement] = useState<HTMLUListElement | null>(null);

  useEffect(
    function showLoaderWhenReachingLastOption() {
      if (highlightedOptionIndex === options.length - 1) {
        listElement?.scrollTo?.(0, listElement.scrollHeight);
      }
    },
    [listElement, highlightedOptionIndex, options.length],
  );

  useEffect(
    function handleScrollToBottom() {
      if (isBottom) {
        onScrollToBottom?.();
      }
    },
    [isBottom, onScrollToBottom],
  );

  return (
    <ul
      ref={setListElement}
      role="listbox"
      className={clsx(['select-list', className])}
    >
      <AnimatePresence mode="popLayout">
        {options.map((option, index) => {
          const key = getOptionKey(option, optionKeyPath) as Key;
          return (
            <ListItem key={key}>
              <SelectListOption
                listElement={listElement ?? undefined}
                isHighlighted={highlightedOptionIndex === index}
                option={option}
                renderContent={renderListOption}
                onOptionSelect={onOptionSelect}
                onOptionHighlight={onOptionHighlight}
                type={type}
                isSelected={selectedOptions.some(
                  (selectedOption) =>
                    getOptionKey(selectedOption, optionKeyPath) === key,
                )}
                disabled={disabledOptions?.some(
                  (selectedOption) =>
                    getOptionKey(selectedOption, optionKeyPath) === key,
                )}
              />
            </ListItem>
          );
        })}
      </AnimatePresence>

      {onScrollToBottom && (
        <li ref={bottomRef} className="select-list__loading">
          <FullWidthLoader className="select-list__loading-icon" />
        </li>
      )}
    </ul>
  );
}

export default SelectList;
