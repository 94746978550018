import {
  VehicleSettingOverrides,
  VehicleSettings,
} from '@motional-cc/fe/interface/api/api-server';
import { useEffect } from 'react';
import { apiServerPaths } from 'src/api/api-server';
import { useApi } from 'src/api/hooks/service';
import Definition from 'src/components/common/Definition';
import FieldSetWithoutAccessibleLabel from 'src/components/common/FieldSetWithoutAccessibleLabel';
import LoadingErrorEmptyState from 'src/components/common/LoadingErrorEmptyState';
import Select from 'src/components/common/Select/Select';
import SelectListOptionDualLabel from 'src/components/common/Select/SelectListOptionDualLabel';
import TextField from 'src/components/common/TextField';
import { isNullish } from 'src/tools/types';
import SelectBoolean from './SelectBoolean';

type PartialVehicleSettingOverrides = {
  [Key in keyof VehicleSettingOverrides]:
    | VehicleSettingOverrides[Key]
    | { value: undefined };
};

interface BaseProps {
  requireAllSettings?: boolean;
  isDisabled?: boolean;
}

type Props = BaseProps &
  (
    | {
        settings?: PartialVehicleSettingOverrides;
        setSettings: (
          settingSetter: (
            oldSettings: PartialVehicleSettingOverrides,
          ) => PartialVehicleSettingOverrides,
        ) => void;
        setDefaultNullValues: true;
      }
    | {
        settings?: VehicleSettingOverrides;
        setSettings: (
          settingSetter: (
            oldSettings: VehicleSettingOverrides,
          ) => VehicleSettingOverrides,
        ) => void;
        setDefaultNullValues?: false;
      }
  );

function VehicleSettingsForm({
  requireAllSettings,
  isDisabled,
  setDefaultNullValues,
  setSettings,
  settings,
}: Props) {
  const {
    result: manifestBehaviors,
    status: manifestStatus,
    error: manifestError,
  } = useApi<VehicleSettings.Manifest.ResponseBody>(
    apiServerPaths.VehicleSettings.Manifest(),
  );

  useEffect(
    function setAllValuesToNull() {
      if (!setDefaultNullValues || !manifestBehaviors) {
        return;
      }

      setSettings((oldSettings) => {
        if (
          !oldSettings ||
          manifestBehaviors.every(
            (behavior) => oldSettings?.[behavior.key]?.value !== undefined,
          )
        ) {
          return oldSettings;
        }

        const newSettings: typeof oldSettings = oldSettings
          ? { ...oldSettings }
          : {};

        manifestBehaviors.forEach((behavior) => {
          if (oldSettings?.[behavior.key]) return;

          newSettings[behavior.key] = { value: undefined };
        });

        return newSettings;
      });
    },
    [manifestBehaviors, setDefaultNullValues, setSettings],
  );

  const handleValueChange =
    (settingName: string) =>
    (newValue: string | boolean | number | undefined) => {
      if (setDefaultNullValues) {
        setSettings((oldSettings) => {
          const newSettings = oldSettings ? { ...oldSettings } : {};

          newSettings[settingName] = { value: newValue };

          return newSettings;
        });
      } else {
        setSettings((oldSettings) => {
          const newSettings = oldSettings ? { ...oldSettings } : {};

          if (isNullish(newValue)) {
            delete newSettings[settingName];
          } else {
            newSettings[settingName] = { value: newValue };
          }

          return newSettings;
        });
      }
    };

  return (
    <LoadingErrorEmptyState
      result={manifestBehaviors}
      status={manifestStatus}
      error={manifestError}
    >
      {(manifestBehaviors) => (
        <ul className="edit-vehicle-settings__setting-list">
          {manifestBehaviors.map((manifestSetting, index) => {
            const value = settings?.[manifestSetting.key]?.value;

            return (
              <li key={index}>
                <FieldSetWithoutAccessibleLabel vertical>
                  <Definition
                    title={manifestSetting.description}
                    definition={manifestSetting.detailed_description}
                  />

                  {manifestSetting.control_details.type === 'boolean' ? (
                    <SelectBoolean
                      label={manifestSetting.name}
                      placeholder={`Default (${manifestSetting.control_details.default_value})`}
                      value={value as boolean | undefined}
                      onSelect={handleValueChange(manifestSetting.key)}
                      disabled={isDisabled}
                      allowClear={!requireAllSettings}
                      required={requireAllSettings}
                      renderListOption={(value) =>
                        value &&
                        `${manifestSetting.control_details.default_value}` ===
                          `${value}` ? (
                          <SelectListOptionDualLabel
                            primaryLabel={`${value}`}
                            secondaryLabel="Default value"
                          />
                        ) : (
                          value
                        )
                      }
                      renderSelectedOption={(value) =>
                        value &&
                        `${manifestSetting.control_details.default_value}` ===
                          `${value}`
                          ? `${value} (default)`
                          : `${value}`
                      }
                    />
                  ) : manifestSetting.control_details.type === 'enum' ? (
                    <Select
                      required={requireAllSettings}
                      allowClear={!requireAllSettings}
                      onSelect={handleValueChange(manifestSetting.key)}
                      label={manifestSetting.name}
                      value={value}
                      placeholder={`Default (${manifestSetting.control_details.default_value})`}
                      disabled={isDisabled}
                      options={
                        manifestSetting.control_details.constraints?.values ??
                        []
                      }
                    />
                  ) : (
                    // TODO: when we have some other types to test with, implement them here
                    // <DecimalField
                    //   label={behavior.name}
                    //   onChange={handleValueChange(behavior.key)}
                    //   placeholder={`default (${behavior.control_details.default_value})`}
                    //   min={behavior.control_details.constraints?.minimum}
                    //   max={behavior.control_details.constraints?.maximum}
                    //   value={
                    //     isRealNumber(summaryValue) ? String(summaryValue) : ''
                    //   }
                    // />
                    <TextField
                      disabled
                      label={manifestSetting.name}
                      placeholder={`${manifestSetting.control_details.type} settings not yet supported`}
                      value=""
                    />
                  )}
                </FieldSetWithoutAccessibleLabel>
              </li>
            );
          })}
        </ul>
      )}
    </LoadingErrorEmptyState>
  );
}

export default VehicleSettingsForm;
