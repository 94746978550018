import type { StringBoolean } from 'src/interface/command-center/unsorted-types';
import { ReactNode } from 'react';
import Select from 'src/components/common/Select/Select';
import { isNullish } from 'src/tools/types';

const getValue = (value?: boolean | StringBoolean) =>
  isNullish(value)
    ? undefined
    : typeof value === 'boolean'
    ? value
    : value === 'true';

interface Props {
  label: string;
  value?: boolean | StringBoolean;
  placeholder?: string;
  renderSelectedOption?: (value: boolean | string | undefined) => string;
  renderListOption?: (value: boolean | string | undefined) => ReactNode;
  onSelect: (newValue: boolean | undefined) => void;
  disabled?: boolean;
  allowClear?: boolean;
  required?: boolean;
}

function SelectBoolean({
  label,
  value: valueProps,
  placeholder,
  renderListOption = (value) => (isNullish(value) ? '' : `${value}`),
  renderSelectedOption = (value) => (isNullish(value) ? '' : `${value}`),
  onSelect,
  disabled,
  allowClear = true,
  required,
}: Props) {
  const value = getValue(valueProps);
  const handleSelect = (newValue: StringBoolean | undefined) => {
    onSelect(getValue(newValue));
  };

  return (
    <Select<StringBoolean>
      allowClear={allowClear}
      required={required}
      onSelect={handleSelect}
      label={label}
      options={['true', 'false']}
      value={isNullish(value) ? undefined : `${value}`}
      placeholder={placeholder ?? 'True or false'}
      renderSelectedOption={renderSelectedOption}
      renderListOption={renderListOption}
      disabled={disabled}
    />
  );
}

export default SelectBoolean;
