import { captureException } from '@sentry/browser';
import { FullOffsetString, fullOffsetRegExp } from './getOffsetFromTimeZone';

export const getOffsetStringMinutes = (offsetString: FullOffsetString) => {
  const matchData = offsetString.match(fullOffsetRegExp);

  if (!matchData?.groups) {
    captureException(
      new Error(
        `Timezone string parse Error: [${offsetString}] cannot parse offset`,
      ),
    );
    return 0;
  }

  const { sign, hour, minute } = matchData.groups;
  let result = parseInt(hour) * 60;
  if (sign === '-') {
    result *= -1;
  }
  if (minute) {
    result += parseInt(minute);
  }

  return result;
};
