import t from '@motional-cc/fe/tools/translate';
import dateFnsFormat from 'date-fns/format';
import { DateTimeFormat, UNIVERSAL_DATETIME_FORMATS } from './constants';
import { AsTimezone, getDateFromIso } from './getDateFromIso';
import { inMilliseconds } from './inMilliseconds';

export function renderDateTime(
  _dateRepresentation: string | number | Date,
  {
    format = 'short date',
    fromServer = false,
    asTimezone = 'original',
  }: {
    format?: DateTimeFormat;
    fromServer?: boolean;
    asTimezone?: AsTimezone;
  } = {},
) {
  const dateRepresentation =
    typeof _dateRepresentation === 'string'
      ? getDateFromIso(_dateRepresentation, { asTimezone })
      : _dateRepresentation;

  const translatedFormat =
    UNIVERSAL_DATETIME_FORMATS[format] ?? t(`date-format.${format}`);
  try {
    return dateFnsFormat(
      fromServer && typeof dateRepresentation === 'number'
        ? inMilliseconds(dateRepresentation, 'seconds')
        : dateRepresentation,
      translatedFormat,
    );
  } catch (_error) {
    const error = _error as Error;
    if (error instanceof RangeError) {
      // This probably indicates translations haven’t been updated
      throw new Error(
        `Couldn’t format date “${
          _dateRepresentation instanceof Date
            ? +_dateRepresentation
            : _dateRepresentation
        }” using “${translatedFormat}”`,
      );
    } else {
      throw error;
    }
  }
}
