import { Region } from '@motional-cc/fe/interface/api/api-concierge';
import { getIsoDateParts } from './getIsoDateParts';
import { inMilliseconds } from './inMilliseconds';
import { REGION_TIMEZONE_MINUTES } from './regionTimeZoneMinutes';

export type AsTimezone = 'original' | 'client' | 'utc' | Region;

function getOriginalDateFromIso(isoDate: string): Date {
  const { year, month, day, hours, minutes, seconds, milliseconds } =
    getIsoDateParts(isoDate);

  if (typeof year !== 'number' || typeof month !== 'number') {
    // TODO: return null and fix all the issues that arise from that 🙈
    return new Date();
  }

  return new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);
}

function getClientDateFromIso(isoDate: string): Date {
  return new Date(isoDate);
}

function getUtcDateFromIso(isoDate: string): Date {
  const localDate = getClientDateFromIso(isoDate);

  return new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
    localDate.getUTCHours(),
    localDate.getUTCMinutes(),
    localDate.getUTCSeconds(),
    localDate.getUTCMilliseconds(),
  );
}

function getRegionDateFromIso(isoDate: string, timeZone: Region): Date {
  const utcDate = getUtcDateFromIso(isoDate);

  return new Date(
    utcDate.getTime() +
      inMilliseconds(REGION_TIMEZONE_MINUTES[timeZone], 'minutes'),
  );
}

export function getDateFromIso(
  isoDate: string,
  { asTimezone = 'original' }: { asTimezone?: AsTimezone } = {},
): Date {
  if (asTimezone === 'original') {
    return getOriginalDateFromIso(isoDate);
  }

  if (asTimezone === 'client') {
    return getClientDateFromIso(isoDate);
  }

  if (asTimezone === 'utc') {
    return getUtcDateFromIso(isoDate);
  }

  return getRegionDateFromIso(isoDate, asTimezone);
}
