import { Region } from '@motional-cc/fe/interface/api/api-concierge';
import { TimeZoneName } from './getOffsetFromTimeZone';

// get list of timezone names: Intl.supportedValuesOf('timeZone')
export const REGION_TIMEZONE_NAMES: {
  [RegionName in Region]: TimeZoneName;
} = {
  boston: 'America/New_York',
  singapore: 'Asia/Singapore',
  las_vegas: 'America/Los_Angeles',
  pittsburgh: 'America/New_York',
  santa_monica: 'America/Los_Angeles',
  san_diego: 'America/Los_Angeles',
  austin: 'America/Chicago',
} as const;
