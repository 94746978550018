import {
  PartnerName,
  Vehicles,
} from '@motional-cc/fe/interface/api/api-server';
import { FormEvent, useState } from 'react';
import { apiServerPaths } from 'src/api/api-server';
import { useApi, useMutateApi } from 'src/api/hooks/service';
import ButtonField from 'src/components/common/ButtonField';
import FieldSet from 'src/components/common/FieldSet';
import SelectPartner from 'src/components/common/Select/SelectPartner';
import TextField from 'src/components/common/TextField';
import useDialog from 'src/components/Dialog/dialog-hook';
import { useMessages } from 'src/components/Messages/messages-context';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { SAFE_FALLBACK_PARTNER_NAME } from 'src/interface/command-center/unsorted-consts';
import useDebouncedValue from 'src/tools/hooks/useDebouncedValue';
import { renderPartnerLabel } from 'src/tools/translate';
import { vehicleVinRegexp } from './utils';
import './PartnerVehicleForm.scss';

const TIME_TO_PERSIST_DATA = 1000;

interface Props {
  partner?: PartnerName;
  vehicleId?: string;
  vin?: string;
  onSaveError?: () => void;
  onSaveSuccess?: () => void;
  onCancelClick?: () => void;
}

function PartnerVehicleForm({
  partner: partnerNameProps,
  vehicleId: vehicleIdProps = '',
  vin: vinProps = '',
  onSaveError,
  onSaveSuccess,
  onCancelClick,
}: Props) {
  const { showMessage } = useMessages();
  const { openDialog: openDeleteDialog, DialogComponent } = useDialog();

  const [submitWasAttempted, setSubmitWasAttempted] = useState(false);
  const [partnerName, setPartnerName] = useState(partnerNameProps);
  const [vin, setVin] = useState(vinProps);
  const debouncedVin = useDebouncedValue(vin, 300);
  const [partnerVehicleId, setPartnerVehicleId] = useState(vehicleIdProps);
  const isEditMode = typeof partnerNameProps === 'string';
  const debouncedVinIsSynced = vin === debouncedVin;
  const { result: existingPartners, refetch: refetchExistingPartners } =
    useApi<Vehicles.PartnersDetail.ResponseBody>(
      apiServerPaths.Vehicles.PartnersDetail(debouncedVin),
      {
        enabled: !isEditMode && !!partnerName && !!vin && debouncedVinIsSynced,
      },
    );
  const existingPartnerVehicle = existingPartners?.find(
    (partner) => partner.name === partnerName,
  );

  const { mutate: editPartnerVehicle, isLoading: isEditing } = useMutateApi<
    Vehicles.PartnersUpdate.ResponseBody,
    Vehicles.PartnersUpdate.RequestBody
  >(
    apiServerPaths.Vehicles.PartnersUpdate(
      vin,
      partnerName || SAFE_FALLBACK_PARTNER_NAME,
    ),
    'PUT',
  );

  const { mutate: deletePartnerVehicle, isLoading: isDeleting } = useMutateApi<
    Vehicles.PartnersDelete.ResponseBody,
    Vehicles.PartnersDelete.RequestBody
  >(
    apiServerPaths.Vehicles.PartnersDelete(
      vin,
      partnerName || SAFE_FALLBACK_PARTNER_NAME,
    ),
    'DELETE',
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formIsInvalid) {
      setSubmitWasAttempted(true);
      return;
    }

    try {
      await editPartnerVehicle({
        partner_vehicle_id: partnerVehicleId,
      });

      showMessage({
        type: 'success',
        title: `${renderPartnerLabel(partnerName)} vehicle ${vin} has been
            added successfully`,
      });
      onSaveSuccess?.();

      setTimeout(() => {
        refetchExistingPartners();
      }, TIME_TO_PERSIST_DATA);
    } catch (_error) {
      const error = _error as ApiError;

      showMessage({
        type: 'error',
        title: `Failed to add vehicle ${vin} to ${renderPartnerLabel(
          partnerName,
        )}`,
        description: error.message,
      });
      onSaveError?.();

      setTimeout(() => {
        refetchExistingPartners();
      }, TIME_TO_PERSIST_DATA);
    }
  };

  const handleDeleteClick = () => {
    openDeleteDialog();
  };

  const handleConfirmDeleteClick = async () => {
    try {
      await deletePartnerVehicle();

      refetchExistingPartners();
      onSaveSuccess?.();

      showMessage({
        type: 'success',
        title: `${renderPartnerLabel(
          partnerName,
        )} vehicle ${vin} has been deleted successfully`,
      });
    } catch (_error) {
      const error = _error as ApiError;

      refetchExistingPartners();
      onSaveError?.();

      showMessage({
        type: 'error',
        title: `Failed to delete vehicle ${vin} from ${renderPartnerLabel(
          partnerName,
        )}`,
        description: error.message,
      });
    }
  };

  const formIsInvalid = !(
    partnerName &&
    (vehicleIdProps || vin) &&
    partnerVehicleId
  );

  const disabled = isEditing || isDeleting;

  return (
    <>
      <form className="partner-vehicle-form" onSubmit={handleSubmit}>
        <FieldSet
          vertical
          hideLabel
          label="Create partner vehicle"
          className="partner-vehicle-form__fields"
        >
          {isEditMode ? (
            <TextField
              isStatic
              label="Partner"
              placeholder="Partner"
              value={renderPartnerLabel(partnerName)}
            />
          ) : (
            <>
              <TextField
                required
                label="VIN"
                placeholder="Paste the VIN to link"
                value={vin}
                onChange={setVin}
                disabled={isEditMode || disabled}
                showErrorMessage={submitWasAttempted}
                pattern={vehicleVinRegexp}
              />

              <SelectPartner<'api-server'>
                required
                onSelect={setPartnerName}
                partner={partnerName}
                disabled={disabled}
                showErrorMessage={submitWasAttempted}
              />
            </>
          )}

          {!isEditMode && existingPartnerVehicle && (
            <p className="partner-vehicle-form__warning-message">
              This VIN is already assigned to {renderPartnerLabel(partnerName)}.
              This action will update the designated partner vehicle ID.
            </p>
          )}

          <TextField
            required
            label="Partner vehicle ID"
            placeholder="Paste the ID the partner uses"
            value={partnerVehicleId}
            onChange={setPartnerVehicleId}
            disabled={disabled}
            showErrorMessage={submitWasAttempted}
          />

          {isEditMode && (
            <ButtonField
              disabled={disabled}
              className="partner-vehicle-form__action partner-vehicle-form__action--delete"
              onClick={handleDeleteClick}
              label="Delete partner data"
              color="text-destructive"
              size="medium"
            />
          )}
        </FieldSet>

        <FieldSet
          horizontal
          fill
          hideLabel
          label="Partner vehicle form actions"
          className="partner-vehicle-form__actions"
        >
          {onCancelClick && (
            <ButtonField
              disabled={disabled}
              className="partner-vehicle-form__action partner-vehicle-form__action--cancel"
              onClick={onCancelClick}
              label="Cancel"
              size="medium"
            />
          )}

          <ButtonField
            allowInteractionWhenDisabled={!disabled}
            className="partner-vehicle-form__action partner-vehicle-form__action--submit"
            disabled={disabled || formIsInvalid}
            type="submit"
            label={existingPartnerVehicle || isEditMode ? 'Update' : 'Create'}
            color="call-to-action"
            size="medium"
          />
        </FieldSet>
      </form>

      <DialogComponent
        title="Delete partner data"
        warningText="Delete"
        onWarningClick={handleConfirmDeleteClick}
        description={`Are you sure you want to unregister ${renderPartnerLabel(
          partnerName,
        )} from this vehicle? Please check with ${renderPartnerLabel(
          partnerName,
        )} if any action is needed on their side.`}
      />
    </>
  );
}

export default PartnerVehicleForm;
