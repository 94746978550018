import type { PartnerName } from '@motional-cc/fe/interface/api/api-server';
import type { Region } from '@motional-cc/fe/interface/api/registrar';
import { Position2d } from './unsorted-types';

// locations for centering the map
export const REGION_COORDS: Readonly<{
  [key in Region]: Readonly<Position2d>;
}> = {
  boston: [42.344028, -71.028831],
  singapore: [1.298373, 103.786542],
  pittsburgh: [40.499518, -79.869974],
  las_vegas: [36.1223414, -115.1177555],
  santa_monica: [34.019480613747795, -118.49141485440221],
  san_diego: [32.715736, -117.161087],
  austin: [30.262855634031023, -97.7433355897665],
} as const;

export const SAFE_FALLBACK_PARTNER_NAME: PartnerName = 'uber_fake';
