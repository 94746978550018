import clsx from 'clsx';
import { FormEvent, ReactNode } from 'react';
import { ButtonColor } from 'src/components/common/Button';
import ButtonField from 'src/components/common/ButtonField';
import Tooltip from 'src/components/common/Tooltip';
import './VerticalForm.scss';

type Props = {
  isSubmitting?: boolean;
  children: ReactNode;
  className?: string;
  onSubmit: () => void;
  onSubmitText?: ReactNode;
  onSubmitColor?: ButtonColor;
  onFailedSubmit?: () => void;
  onCancel?: () => void;
} & (
  | {
      isValid?: never;
      errorMessage: string | undefined;
    }
  | {
      isValid: boolean;
      errorMessage?: string;
    }
);

function VerticalForm({
  isValid: isValidProps,
  errorMessage,
  isSubmitting,
  children,
  className,
  onSubmit,
  onSubmitText,
  onSubmitColor,
  onFailedSubmit,
  onCancel,
}: Props) {
  const isValid = isValidProps ?? !errorMessage;
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isValid) {
      onSubmit();
    } else {
      onFailedSubmit?.();
    }
  };

  return (
    <form
      className={clsx([className, 'vertical-form'])}
      onSubmit={handleSubmit}
    >
      <div className="vertical-form__fields">{children}</div>

      <div className="vertical-form__actions">
        {onCancel && (
          <ButtonField
            size="small"
            label="Cancel"
            disabled={isSubmitting}
            onClick={onCancel}
          />
        )}

        <Tooltip content={errorMessage}>
          <ButtonField
            allowInteractionWhenDisabled
            type="submit"
            size="small"
            label={onSubmitText ?? 'Submit'}
            color={onSubmitColor ?? 'call-to-action'}
            disabled={!isValid || isSubmitting}
          />
        </Tooltip>
      </div>
    </form>
  );
}

export default VerticalForm;
