import { VehicleResponse } from '@motional-cc/fe/interface/api/api-concierge';
import t from '@motional-cc/fe/tools/translate';
import { useMemo } from 'react';
import { userApi } from 'src/api/user';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { DataLoadStatus } from 'src/interface/command-center/unsorted-types';
import { isNotNullish, isNullish } from 'src/tools/types';
import { isRide } from './isRide';

type ReasonType = 'permission' | 'loading' | 'conflict';

type VehicleBase = Partial<
  Pick<
    VehicleResponse,
    | 'carId'
    | 'vin'
    | 'currentTrip'
    | 'dispatchMode'
    | 'connected'
    | 'availabilityState'
  >
>;

interface ConnectedProps {
  vehicle?: VehicleBase | null;
  loadError?: ApiError | null;
  loadStatus?: DataLoadStatus;
}

interface UnconnectedProps extends ConnectedProps {
  hasPermission: boolean | null;
}

export function useCannotEditVehiclePartnerReasonUnconnected({
  vehicle,
  loadError,
  loadStatus,
  hasPermission,
}: UnconnectedProps): { type?: ReasonType; message?: string } {
  return useMemo(() => {
    if (isNullish(hasPermission)) {
      return {
        type: 'permission',
        message: t(
          'vehiclePartnerPreferences.cannotEditWithoutKnownPermissions',
        ),
      };
    }

    if (!hasPermission) {
      return {
        type: 'permission',
        message: t('vehiclePartnerPreferences.cannotEditWithoutPermission'),
      };
    }

    // 404 usually means that the vehicle doesn’t exist, in which case it’s not… really? an error
    // So 404 should fall through to the next error, “cannot edit without a vehicle”
    if (loadError && loadError.status !== 404) {
      return {
        type: 'loading',
        message: t('vehiclePartnerPreferences.unableToFindVehicle'),
      };
    }

    if (!vehicle) {
      if (isNotNullish(loadStatus) && loadStatus !== 'success') {
        return {
          type: 'loading',
          message: t('vehiclePartnerPreferences.cannotEditWhileLoadingVehicle'),
        };
      } else {
        return {
          type: 'loading',
          message: t('vehiclePartnerPreferences.cannotEditWithoutAVehicle'),
        };
      }
    }

    const isGhostVehicle = vehicle.vin && !vehicle.carId;
    if (isGhostVehicle) {
      return { type: undefined, message: undefined };
    }

    if (
      isRide(vehicle.currentTrip) &&
      vehicle.currentTrip.details.ridePhase !== 'COMPLETED'
    ) {
      return {
        type: 'conflict',
        message: t('vehiclePartnerPreferences.cannotEditDuringRide'),
      };
    }

    const vehicleIsExpectingRides =
      vehicle.connected &&
      vehicle.availabilityState === 'Available' &&
      vehicle.dispatchMode;
    if (vehicleIsExpectingRides) {
      return {
        type: 'conflict',
        message: t('vehiclePartnerPreferences.cannotEditWhileExpectingRides'),
      };
    }

    return { type: undefined, message: undefined };
  }, [hasPermission, loadError, loadStatus, vehicle]);
}

export function useCannotEditPartnerPreferencesReason(props: ConnectedProps) {
  const canManageCurrentPreferences = userApi.useHasScopes([
    'manage:vehicle-partner',
  ]);

  return useCannotEditVehiclePartnerReasonUnconnected({
    ...props,
    hasPermission: canManageCurrentPreferences,
  });
}

export function useCannotEditPartnerVehicleReason(props: ConnectedProps) {
  const canManageCurrentPreferences = userApi.useHasScopes([
    'manage:partner-vehicle-data',
  ]);

  return useCannotEditVehiclePartnerReasonUnconnected({
    ...props,
    hasPermission: canManageCurrentPreferences,
  });
}
