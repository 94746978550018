import {
  VehicleSettingOverrides,
  VehicleSettings,
} from '@motional-cc/fe/interface/api/api-server';
import { useQueryClient } from '@tanstack/react-query';
import { FormEvent, useEffect, useState } from 'react';
import { apiServerPaths } from 'src/api/api-server';
import { useApi, useMutateApi } from 'src/api/hooks/service';
import ButtonField from 'src/components/common/ButtonField';
import EmptyState from 'src/components/common/EmptyState';
import FieldSetWithoutAccessibleLabel from 'src/components/common/FieldSetWithoutAccessibleLabel';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { isIncompleteStatus } from 'src/interface/command-center/unsorted-type-guards';
import VehicleSettingsForm from './VehicleSettingsForm';
import './EditVehicleSettings.scss';

interface Props {
  carId: string;
  onEditSuccess?: () => void;
}

function EditVehicleSettings({ carId, onEditSuccess }: Props) {
  const queryClient = useQueryClient();
  const {
    result: settingsResponse,
    error: settingsError,
    status: settingsStatus,
  } = useApi<VehicleSettings.GetPublicVehicleOverridesVehicleId.ResponseBody>(
    apiServerPaths.VehicleSettings.GetPublicVehicleOverridesVehicleId(carId),
    {
      knownErrorCodes: ['not_found'],
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );

  const { mutate, isLoading } = useMutateApi<
    VehicleSettings.PutPublicVehicleOverridesVehicleId.ResponseBody,
    VehicleSettings.PutPublicVehicleOverridesVehicleId.RequestBody
  >(
    apiServerPaths.VehicleSettings.PutPublicVehicleOverridesVehicleId(carId),
    'PUT',
  );

  const [settings, setSettings] = useState<VehicleSettingOverrides | undefined>(
    settingsResponse,
  );
  useEffect(
    function syncInitialSettings() {
      if (settings || !settingsResponse) return;

      setSettings(settingsResponse);
    },
    [settings, settingsResponse],
  );

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await mutate(settings || {});
      onEditSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [
          apiServerPaths.VehicleSettings.GetPublicVehicleOverridesVehicleId(
            carId,
          ),
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          apiServerPaths.VehicleSettings.GetPublicBehaviorsVehicleSummariesVehicleId(
            carId,
          ),
        ],
      });
    } catch (_error) {
      const error = _error as ApiError;
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  };

  if (isIncompleteStatus(settingsStatus)) {
    return <FullWidthLoader />;
  }

  // If the error is `not_found` then it likely means that the vehicle has no settings,
  //   not that we can’t set some
  if (settingsError && settingsError.code !== 'not_found') {
    return <EmptyState title="Error fetching settings" />;
  }

  return (
    <div className="edit-vehicle-settings">
      <form onSubmit={handleFormSubmit}>
        <FieldSetWithoutAccessibleLabel vertical>
          <VehicleSettingsForm settings={settings} setSettings={setSettings} />

          <ButtonField
            label="Save"
            type="submit"
            size="medium"
            color="call-to-action"
            disabled={isLoading || !settings}
          />
        </FieldSetWithoutAccessibleLabel>
      </form>
    </div>
  );
}

export default EditVehicleSettings;
