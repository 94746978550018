import { captureException } from '@sentry/browser';

type OffsetString = `${'+' | '-'}${number}${`:${number}` | ''}`;
export type FullOffsetString = `${'+' | '-'}${number}:${number}`;

export const fullOffsetRegExp = /^(?<sign>[+-])(?<hour>\d+)(:(?<minute>\d+))$/;
const offsetRegExp = /^(?<sign>[+-])(?<hour>\d+)(:(?<minute>\d+))?$/; // note question mark at the end

const isOffsetString = (value: string): value is OffsetString =>
  offsetRegExp.test(value);
const isFullOffsetString = (value: string): value is FullOffsetString =>
  fullOffsetRegExp.test(value);

// get list of timezone names: Intl.supportedValuesOf('timeZone')
export type TimeZoneName = NonNullable<
  NonNullable<Parameters<typeof Intl.DateTimeFormat>[1]>['timeZone']
>;

export const getOffsetFromTimeZone = (
  timeZone: TimeZoneName,
): FullOffsetString => {
  let dateTimeFormat: Intl.DateTimeFormat;
  try {
    // 'ia' format returns a time zone name like "GMT-10:30"
    dateTimeFormat = new Intl.DateTimeFormat('ia', {
      timeZoneName: 'short',
      timeZone,
    });
  } catch (_error) {
    const error = _error as RangeError;
    captureException(
      new Error(`Region parse Error: [${timeZone}] timeZone does not exist`, {
        cause: error,
      }),
    );

    return '+00:00';
  }

  const dateTimeParts = dateTimeFormat.formatToParts();
  const timeZonePart = dateTimeParts.find((i) => i.type === 'timeZoneName');
  if (!timeZonePart) {
    captureException(
      new Error(
        `Region parse Error: [${timeZone}] missing timeZonePart: ${dateTimeParts.map(
          (part) => part.type,
        )}`,
      ),
    );

    return '+00:00';
  }

  const offsetString = timeZonePart.value.slice(3);
  if (!isOffsetString(offsetString)) {
    captureException(
      new Error(
        `Region parse Error: [${timeZone}] offset string is invalid: ${offsetString}`,
      ),
    );

    return '+00:00';
  }

  if (isFullOffsetString(offsetString)) {
    return offsetString;
  } else if (/(-|\+)(\d)(:|$)/.test(offsetString)) {
    // add a leading zero to single digit hours
    return `${offsetString}:00`.replace(
      /(-|\+)(\d):/,
      '$10$2:',
    ) as FullOffsetString;
  } else {
    return `${offsetString}:00`;
  }
};
