import type { FieldSpacing } from 'src/interface/command-center/unsorted-types';
import clsx from 'clsx';
import { ReactNode, Ref, forwardRef, useId, useRef, useState } from 'react';
import FieldWrapper from 'src/components/common/FieldWrapper';
import Icon from 'src/components/common/Icon';
import { AllOrNoneOf } from 'src/interface/utility';
import { isNotNullish } from 'src/tools/types';
import {
  AutocompleteProps,
  CommonProps,
  SecondaryActionProps,
} from './interface';
import SelectBase from './SelectBase';
import './SelectField.scss';

// without this React forwards the generic as unknown
declare module 'react' {
  function forwardRef<T, P = unknown>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

type SelectFieldProps<Option> = {
  selectedOptions?: Readonly<Option[]>;
  disabledOptions?: Readonly<Option[]>;
  closeOnSelect?: boolean;
  spacing?: FieldSpacing;
  className?: string;
  disabled?: boolean;
  isStatic?: boolean;
  required?: boolean;
  type?: 'checkbox' | 'radio';

  onSelect: (option: Option) => void;
  onRemoveLast?: () => void;
  onClear?: () => void;

  renderSelected: (selectedOptions: Readonly<Option[]>) => ReactNode;
};

type Props<Option> = CommonProps<Option> &
  SelectFieldProps<Option> &
  AllOrNoneOf<AutocompleteProps> &
  AllOrNoneOf<SecondaryActionProps>;

function SelectField<Option>(
  {
    label,
    type,
    hideLabel,
    inputId,
    className,
    disabled,
    isStatic,
    required,
    autoFocus,
    placeholder = '',
    closeOnSelect,
    options,
    optionKeyPath,
    selectedOptions,
    disabledOptions,
    searchText,
    searchPlaceholder,
    errorMessage: propsErrorMessage,
    showErrorMessage: propsShowErrorMessage,
    renderListOption,
    renderSelectedOption,
    renderSelected,

    secondaryActionLabel,
    onSecondaryActionClick,

    onSearchTextChange,
    onScrollToBottom,
    onRemoveLast,
    onSelect,
    onClear,
    ...rest
  }: Props<Option>,
  ref: Ref<HTMLSpanElement>,
) {
  const [toggleElement, setToggleElement] = useState<HTMLButtonElement | null>(
    null,
  );
  const [fieldElement, setFieldElement] = useState<HTMLSpanElement | null>(
    null,
  );

  const optionsListId = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);

  const errorMessage =
    propsErrorMessage ??
    (required && !isOpen && selectedOptions?.length === 0
      ? `${label} is required`
      : undefined);

  const contentRef = useRef<HTMLDivElement>(null);
  const secondaryActionProps =
    onSecondaryActionClick && secondaryActionLabel
      ? { onSecondaryActionClick, secondaryActionLabel }
      : {};

  const handleOptionSelect = (option: Option) => {
    if (isNotNullish(option)) {
      onSelect(option);
    }
  };

  const handleClearClick = () => {
    onClear?.();
  };

  const handleOptionsOpen = () => {
    setWasOpened(true);
    setIsOpen(true);
  };

  const handleOptionsClose = () => {
    setIsOpen(false);
  };

  const handleSelectToggleClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <FieldWrapper
      ref={ref || setFieldElement}
      inputId={inputId}
      label={label}
      hideLabel={hideLabel}
      placeholder={placeholder}
      required={required}
      errorMessage={errorMessage}
      showErrorMessage={propsShowErrorMessage ?? wasOpened}
      endAdornmentLabel={isOpen ? `Close ${label}` : `Open ${label}`}
      disabled={disabled}
      isStatic={isStatic}
      className={clsx([
        'select-field',
        className,
        { 'select-field--expanded': isOpen },
        { 'select-field--disabled': disabled },
        { 'select-field--is-static': isStatic },
      ])}
      renderEndIcon={() => (
        <Icon
          className="select-field__toggle-options-icon"
          name="TriangleDown"
        />
      )}
      {...secondaryActionProps}
      {...rest}
    >
      {({ className, id, errorId, disabled, placeholder }) => (
        <span ref={contentRef} className="select-field__content">
          <span className="select-field__selected-wrapper">
            <button
              ref={setToggleElement}
              type="button"
              aria-label={`Toggle ${label} options`}
              autoFocus={autoFocus}
              className={clsx(['select-field__selected-button', className])}
              onClick={handleSelectToggleClick}
              disabled={disabled}
              id={id}
              aria-describedby={errorId}
            />

            {selectedOptions?.length ? (
              renderSelected(selectedOptions)
            ) : (
              <span className="select-field__placeholder-text">
                {placeholder || '—'}
              </span>
            )}
          </span>

          {!disabled && onClear && !!selectedOptions?.length && (
            <button
              type="button"
              aria-label={`Clear ${label} options`}
              aria-describedby={isOpen ? optionsListId : undefined}
              className="select-field__clear-button"
              onClick={handleClearClick}
            >
              <Icon name="MultiplyFat" className="select-field__clear-icon" />
            </button>
          )}

          {toggleElement && fieldElement && (
            <SelectBase
              type={type}
              isOpen={isOpen}
              closeOnSelect={closeOnSelect}
              selectedOptions={selectedOptions}
              disabledOptions={disabledOptions}
              toggleElement={toggleElement}
              anchorElement={fieldElement}
              searchText={searchText}
              options={options}
              optionKeyPath={optionKeyPath}
              renderListOption={renderListOption}
              onSearchTextChange={onSearchTextChange}
              onScrollToBottom={onScrollToBottom}
              onSelect={handleOptionSelect}
              onClose={handleOptionsClose}
              onRemoveLast={onRemoveLast}
              onOpen={handleOptionsOpen}
              searchPlaceholder={searchPlaceholder}
            />
          )}
        </span>
      )}
    </FieldWrapper>
  );
}

export default forwardRef(SelectField);
