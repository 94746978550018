import { Region } from '@motional-cc/fe/interface/api/api-concierge';
import { getOffsetFromTimeZone } from './getOffsetFromTimeZone';
import { getOffsetStringMinutes } from './getOffsetStringMinutes';
import { REGION_TIMEZONE_NAMES } from './regionTimeZoneNames';

export const REGION_TIMEZONE_MINUTES: {
  [RegionName in Region]: number;
} = {
  boston: getOffsetStringMinutes(
    getOffsetFromTimeZone(REGION_TIMEZONE_NAMES.boston),
  ),
  singapore: getOffsetStringMinutes(
    getOffsetFromTimeZone(REGION_TIMEZONE_NAMES.singapore),
  ),
  las_vegas: getOffsetStringMinutes(
    getOffsetFromTimeZone(REGION_TIMEZONE_NAMES.las_vegas),
  ),
  pittsburgh: getOffsetStringMinutes(
    getOffsetFromTimeZone(REGION_TIMEZONE_NAMES.pittsburgh),
  ),
  santa_monica: getOffsetStringMinutes(
    getOffsetFromTimeZone(REGION_TIMEZONE_NAMES.santa_monica),
  ),
  san_diego: getOffsetStringMinutes(
    getOffsetFromTimeZone(REGION_TIMEZONE_NAMES.san_diego),
  ),
  austin: getOffsetStringMinutes(
    getOffsetFromTimeZone(REGION_TIMEZONE_NAMES.austin),
  ),
} as const;
