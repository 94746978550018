import { VehicleSettings } from '@motional-cc/fe/interface/api/api-server';
import t from '@motional-cc/fe/tools/translate';
import { apiServerPaths } from 'src/api/api-server';
import { useApi } from 'src/api/hooks/service';
import LogoLoader from 'src/components/common/LogoLoader';
import { CellProps } from 'src/components/common/Table/interface';
import TableCell, {
  CONTENT_CLASS,
} from 'src/components/common/Table/TableCell';
import { isIncompleteStatus } from 'src/interface/command-center/unsorted-type-guards';
import { objectEntries } from 'src/tools/object/objectEntries';
import './VehicleSettingsCell.scss';

function VehicleSettingsCell({ cell: carId }: CellProps<string>) {
  const { result: vehicleSummary, status } =
    useApi<VehicleSettings.GetPublicBehaviorsVehicleSummariesVehicleId.ResponseBody>(
      apiServerPaths.VehicleSettings.GetPublicBehaviorsVehicleSummariesVehicleId(
        carId,
      ),
    );

  return (
    <TableCell className="vehicle-settings-cell">
      <span className={CONTENT_CLASS}>
        {isIncompleteStatus(status) ? (
          <LogoLoader className="the-table__cell-loader" />
        ) : (
          <span className="vehicle-settings-cell__content">
            {vehicleSummary?.settings &&
              objectEntries(vehicleSummary?.settings)
                .sort(([settingNameA], [settingNameB]) =>
                  settingNameA.localeCompare(settingNameB),
                )
                .map(([settingName, setting]) => (
                  <p
                    key={settingName}
                    className="vehicle-settings-cell__setting"
                  >
                    {t(`vehicleSettings.setting.${settingName}`) ||
                      setting.name}
                    {': '}
                    {String(setting.value)}
                    <span className="vehicle-settings-cell__setting-source">
                      {` (${
                        t(`vehicleSettings.source.${setting.source}`) ||
                        setting.source
                      })`}
                    </span>
                  </p>
                ))}
          </span>
        )}
      </span>
    </TableCell>
  );
}

export default VehicleSettingsCell;
