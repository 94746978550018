import { Enroll, ModernModel } from '@motional-cc/fe/interface/api/nursery';
import { useMutateApi } from 'src/api/hooks/service';
import { nurseryPaths } from 'src/api/nursery';
import { useMessages } from 'src/components/Messages/messages-context';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import VehicleForm from './VehicleForm';
import './CreateVehicle.scss';

const INITIAL_VALUE: { model: ModernModel } = { model: 'Ioniq' };

type EnrollBody = {
  otp: string;
  car_id: string;
  alias: string;
  model: string;
  platform: string;
  license_plate: string;
  region: string;
  vin: string;
};

interface Props {
  onSaveError?: () => void;
  onSaveSuccess?: () => void;
  onCancelClick?: () => void;
}

function CreateVehicle({ onSaveError, onSaveSuccess, onCancelClick }: Props) {
  const { showMessage } = useMessages();
  const { mutate, error } = useMutateApi<unknown, EnrollBody>(
    nurseryPaths.Enroll.EnrollVehicle(),
    'POST',
  );

  const handleSubmit = async (value: Enroll.EnrollVehicle.RequestBody) => {
    const { license_plate, car_id, alias, ...restValue } = value;

    try {
      await mutate({
        ...restValue,
        license_plate,
        alias,
        car_id,
      });

      onSaveSuccess?.();
      showMessage({
        type: 'success',
        title: `${alias ?? car_id} has been added successfully`,
      });
    } catch (_error) {
      const error = _error as ApiError;

      onSaveError?.();
      showMessage({
        type: 'error',
        title: `An error occured while trying to create ${alias ?? car_id}`,
        description: error.message,
      });
    }
  };

  return (
    <div className="create-vehicle">
      <VehicleForm
        mode="create"
        onSubmit={handleSubmit}
        onCancel={onCancelClick}
        error={error?.message}
        initialValue={INITIAL_VALUE}
      />
    </div>
  );
}

export default CreateVehicle;
