// type ISODateString =
//   | `${number}-${number}-${number}T${number}:${number}:${number}`
//   | `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`;

export function getIsoDateParts(isoDate: string) {
  if (!isoDate) {
    return {
      offset: 0,
    };
  }

  // ISO format: 1970-01-01T00:00:00+00:00
  // TODO: Find a more standard way to deal with this
  const [year, month, day, hours, minutes, seconds, offsetHour, offsetMinute] =
    isoDate.split(/[-+T:]/);
  const timeOffsetDirection = isoDate.includes('+') ? 1 : -1;
  const offset = (+offsetHour * 60 + +offsetMinute) * timeOffsetDirection;

  return {
    year: +year,
    month: +month || 0,
    day: +day || 0,
    hours: +hours || 0,
    minutes: +minutes || 0,
    seconds: parseInt(seconds?.split('.')[0]) || 0,
    milliseconds: parseInt(seconds?.split('.')[1]) || 0,
    offset: +offset || 0,
  };
}
